import './App.css';

function App() {
  return (
    <div className="App">
      <h1>TONEPARK.RU</h1>
      <h2>Сайт находится в разработке</h2>
    </div>
  );
}

export default App;
